*{
  font-family: "GT Walsheim Pro", sans-serif;
}

.bs-datepicker-container {
  padding: 0;

  .btn-clear-wrapper .btn {
    border: none;
    border-radius: 4px;
    margin: 15px;
    padding: 5px 10px;
    background-color: var(--accent-primary-425);
    color: var(--white);
    font-family: "GT Walsheim Pro", sans-serif;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      background-color: var(--accent-primary-425);
    }
  }
}

.bs-datepicker-body {
  table {
    th {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: var(--text-colors-body-100);
      width: 32px;
      height: 32px;
    }

    td {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #3D455F;

      span.is-other-month {
        font-weight: normal;
        font-size: 14px;
        color: #DCDEE6;
      }
    }
  }

  table.days {
    span.in-range:not(.select-start) {
      &:before {
        background: var(--alice-blue);
      }
    }
  }

}

.theme-green, .not-adaptive-datepicker {
  .bs-datepicker-head {
    background: var(--white);

    button {
      font-weight: bold;
      font-size: 16px;
      color: var(--grey);
      &.current{
        color: var(--black);
      }
    }
  }

  .bs-datepicker-body {
    table {
      td {
        span.selected {
          background-color: var(--primary-300);
        }
      }
    }
  }
}

.not-adaptive-datepicker {
  bottom: 50px;
}

.date-range-clear-button {
  .bs-datepicker-container {
    min-height: 370px;

    .btn-clear-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}
