:root {
  //variables from Figma
  --primary-300: #3947D8;
  --primary-100: #F5F5FB;
  --secondary-500: #068A99;
  --secondary-400: #0A9BAB;
  --secondary-300: #28B4C3;
  --secondary-200: #a1d7dd;
  --secondary-100: #DFF4F6;
  --tertiary-300: #38396F;
  --tertiary-200: #5756B3;
  --additional-key-0: #FFF;
  --additional-key-50: #FAFAFB;
  --additional-key-75: #F7F7F9;
  --additional-key-175: #E7E9EE;
  --additional-key-425: #A4ACC2;
  --additional-key-525: #7E88A6;
  --additional-key-550: #747FA0;
  --additional-grey-300: #7E88A6;
  --additional-key-925: #03091B;
  --additional-page-bg: #F5F6FA;
  --additional-interactions-key-0-disable: #ECEDF1;
  --additional-interactions-key-50-disable: #FAFAFB;
  --additional-interactions-key-550-disable: #ADB4C8;
  --additional-interactions-key-50-hover: #F3F4F7;
  --additional-interactions-key-925-disable: #7E88A6;
  --white: #FFFFFF;
  --support-success-300: #0AAB7B;
  --support-success-200: #A7E9CB;
  --support-success-100: #E5F9F0;
  --support-danger-500: #E93737;
  --support-danger-425: #EF8486;
  --support-danger-400: #EF8586; // TODO: 400 and 300 the same. Discuss with designers
  --support-danger-300: #EF8586;
  --support-danger-200: #FA9E9F;
  --support-danger-100: #FEE7E7; // this is marked as 200 in design
  --support-warning-500: #F8BD29;
  --support-warning-300: #FBD678;
  --support-warning-200: #FFE5A3;
  --support-warning-100: #FDF1D2;
  --support-info-300: #006CE4;
  --support-info-200: #87BBF4;
  --support-info-100: #E5EFFA;
  --support-required: #DE063A;
  --text-colors-title-300: #141418;
  --text-colors-title-primary: #3947D8;
  --text-colors-title-secondary: #28B4C3;
  --text-colors-title-tertiary-300: #38396F;
  --text-colors-title-tertiary-200: #5756B3;
  --text-colors-body-300: #141418;
  --text-colors-body-200: #7F89A7;
  --text-colors-body-100: #ACB0BD;
  --text-colors-input-label: #141418;
  --text-colors-input-text: #141418;
  --text-colors-input-placeholder: #7F89A7;
  --text-colors-white: #FFFFFF;
  --separator-on-grey: #E1E9EF;
  --separator-on-white: #E5E7ED;
  --icon-primary: #3947D8;
  --icon-secondary: #28B4C3;
  --icon-400: #141418;
  --icon-300: #7F89A7;
  --icon-200: #CDDAE4;
  --icon-100: #FFFFFF;
  --rating-12-10: #28B4C3;
  --rating-9-7: #F7DC98;
  --rating-6-4: #6C63FF;
  --rating-3-1: #F3B5B4;
  --events-lt-purple: #C4C1FF;
  --events-lt-blue: #A4EDFF;
  --events-blue: #B1D2F9;
  --events-violete: #8891E8;
  --events-skinny: #FCF1D6;
  --events-azure: #EE5FF1;
  --events-yellow: #FFFEA9;
  --events-lt-peachy: #FCD3AE;
  --events-purple: #C09CEE;
  --events-dark-violet: #B1BDFF;
  --events-cian: #A6ECCE;
  --events-lt-red: #FCA3A3;
  --statistics-01: #FBDC8E;
  --statistics-02: #F3B5B4;
  --statistics-03: #28B4C3;
  --statistics-04: #5756B3;
  --statistics-05: #9697B8;
  --statistics-06: linear-gradient(180deg, #28B4C4 0%, #C7EFF9 100%);;
  --statistics-07: #FA9E9F;
  --statistics-08: #A7E9CB;
  --statistics-09: #F1E8D3;
  --statistics-10: #C6E4FB;
  --statistics-class-01: #A2E6D9;
  --statistics-class-02: #7DA5DF;
  --statistics-class-03: #AF8BEB;
  --statistics-class-04: #FEC2C2;
  --statistics-class-05: #F28AEE;
  --statistics-class-06: #FEEDC2;
  --statistics-class-07: #A89D9D;
  --statistics-class-08: #AAEBF5;
  --statistics-class-09: #BAD292;
  --statistics-class-10: #C6E4FB;
  --statistics-class-11: #8E6262;
  --accent-primary-50: #E9F6FF;
  --accent-primary-425: #3947D8;
  --accent-primary-interactions-425-disable: #94B5FF;
  --accent-secondary-15: #F4FCFF;
  --accent-secondary-75: #E7FCFE;
  --accent-secondary-interactions-75-hover: #D2F7FB;
  --accent-secondary-375: #3DB4C2;
  --accent-secondary-375-disable: #C4E8EC;
  --accent-tertiary-50: #E7E7FF;
  --accent-tertiary-450: #5051B6;

  // Font sizes
  --font-size-body: 14px;
  --font-size-title: 36px;
  --font-size-h1: 28px;
  --font-size-h2: 24px;
  --font-size-h3: 20px;
  --font-size-h4: 18px;
  --font-size-h5: 16px;
  --font-size-h6: 12px;

  @media all and (max-width: 768px) {
    --font-size-title: 24px;
    --font-size-h1: 22px;
    --font-size-h2: 20px;
    --font-size-h3: 18px;
    --font-size-h4: 16px;
    --font-size-h5: 14px;
    --font-size-h6: 12px;
  }

  //Will be changed in future
  --black: #000000;
  --cards-header: #F3F9FB;
  --grey: #C4C4C4;
  --border-checkbox: #DAE3EB;
  --scroll-bg: #CAD4DD;
  --scroll-bg-hover: #B3BFC9;
  --input-bg: #F7FBFF;
  --info-bg: #E0F2FF;
  --item-sel: #63BAFF;
  --item-sel-hov: #5DAFF0;
  --alice-blue: #EFF8FF;
  --list-hover: #EBF1F7;
}
