.calendar {
  padding: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  direction: ltr;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  span,tr,td,table,div,p,a{
    font-family: 'GT Walsheim Pro', sans-serif;
  }
}
.calendar:after {
  clear: both;
  content: "";
  display: block
}

.calendar .calendar-rtl {
  direction: rtl
}

.calendar .calendar-rtl .calendar-rtl table tr td span {
  float: right
}

.calendar table {
  margin: auto;
  //border-spacing: 0 5px;
}

.calendar table td, .calendar table th {
  text-align: center;
  width: 20px;
  height: 20px;
  border: none;
  padding: 4px 5px;
  font-size: 12px
}

.calendar .calendar-header {
  width: 100%;
  margin-bottom: 20px;
  border: 0;
}

.calendar .calendar-header table {
  width: 100%
}

.calendar .calendar-header table th {
  font-size: 22px;
  padding: 5px 10px;
  cursor: pointer
}

.calendar .calendar-header table th:hover {
  background: #eee
}

.calendar .calendar-header table th.disabled, .calendar .calendar-header table th.disabled:hover {
  background: 0 0;
  cursor: default;
  color: #fff
}

.calendar .calendar-header table th.next, .calendar .calendar-header table th.prev {
  width: 20px;
  color: var(--additional-grey-300);
}

.calendar .calendar-header .year-title {
  text-align: center;
  width: auto;
  color: var(--text-colors-body-200);
  font-weight: 500;
  font-size: 34px;
  line-height: 54px;
  transition: all ease 0.3s;
}

.calendar .calendar-header .year-neighbor {
  opacity: .4;
  font-size: 30px;
  transition: all ease 0.3s;
}

@media (max-width: 991px) {
  .calendar .calendar-header .year-neighbor {
    display: none
  }
}

.calendar .calendar-header .year-neighbor2 {
  opacity: .2;
  font-size: 28px;
  transition: all ease 0.3s;
}

@media (max-width: 767px) {
  .calendar .calendar-header .year-neighbor2 {
    display: none
  }
}

.calendar .months-container {
  width: 100%;
  display: none
}

.calendar .months-container .month-container {
  float: left;
  text-align: center;
  height: 200px;
  padding: 0;
  margin-bottom: 40px;
}

.calendar .months-container .month-container.month-2 {
  width: 16.66666667%
}

.calendar .months-container .month-container.month-3 {
  width: 25%
}

.calendar .months-container .month-container.month-4 {
  width: 33.33333333%
}

.calendar .months-container .month-container.month-6 {
  width: 50%
}

.calendar .months-container .month-container.month-12 {
  width: 100%
}

.calendar table.month th.month-title {
  padding-bottom: 16px;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: var(--text-colors-title-tertiary-200);
}

.calendar table.month th.day-header {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-colors-body-100);
}

.calendar table.month tr td, .calendar table.month tr th {
  padding: 0
}

.calendar table.month tr td.hidden, .calendar table.month tr th.hidden {
  display: none
}

.calendar table.month td.week-number {
  cursor: default;
  font-weight: 700;
  border-right: 1px solid #eee;
  padding: 5px
}



.calendar table.month td.day .day-content {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-weight: 500;
  padding: 4px 6px;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-colors-body-200);
}

.calendar table.month tr td{
  height: 32px !important;
  width: 32px !important;
}

.calendar table.month td.new, .calendar table.month td.new:hover, .calendar table.month td.old, .calendar table.month td.old:hover {
  background: 0 0;
  cursor: default
}

.calendar table.month td.disabled, .calendar table.month td.disabled:hover {
  color: #ddd
}

.calendar table.month td.disabled .day-content:hover, .calendar table.month td.disabled:hover .day-content:hover {
  background: 0 0;
  cursor: default
}

.calendar table.month td.range .day-content {
  background: rgba(0, 0, 0, .2);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0
}

.calendar table.month td.range.range-start .day-content {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px
}

.calendar table.month td.range.range-end .day-content {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px
}

.calendar .calendar-loading-container {
  position: relative;
  text-align: center;
  min-height: 200px
}

.calendar .calendar-loading-container .calendar-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%)
}

.calendar .calendar-spinner {
  margin: 20px auto;
  width: 80px;
  text-align: center
}

.calendar .calendar-spinner > div {
  width: 16px;
  height: 16px;
  margin: 5px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1s infinite ease-in-out both;
  animation: sk-bouncedelay 1s infinite ease-in-out both
}

.calendar .calendar-spinner > div.bounce1 {
  -webkit-animation-delay: -.32s;
  animation-delay: -.32s
}

.calendar .calendar-spinner > div.bounce2 {
  -webkit-animation-delay: -.16s;
  animation-delay: -.16s
}

.calendar-context-menu, .calendar-context-menu .submenu {
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
  position: absolute;
  display: none
}

.calendar-context-menu .item {
  position: relative
}

.calendar-context-menu .item .content {
  padding: 5px 10px;
  cursor: pointer;
  display: table;
  width: 100%;
  white-space: nowrap
}

.calendar-context-menu .item .content:hover {
  background: #eee
}

.calendar-context-menu .item .content .text {
  display: table-cell
}

.calendar-context-menu .item .content .arrow {
  display: table-cell;
  padding-left: 10px;
  text-align: right
}

.calendar-context-menu .item .submenu {
  top: -1px
}

.calendar-context-menu .item .submenu:not(.open-left) {
  left: 100%
}

.calendar-context-menu .item .submenu.open-left {
  right: 100%
}

.calendar-context-menu .item:hover > .submenu {
  display: block
}

.table-striped .calendar table.month tr td, .table-striped .calendar table.month tr th {
  background-color: transparent;
}

//

.calendar table.month td.day:nth-last-child(-n+2) {
  pointer-events: none;
}


.calendar table.month td.day:nth-last-child(-n+2) .day-content  {
  color: var(--text-colors-body-200);
  pointer-events: none;
  opacity: 0.4;
}

table.month td.day .day-content:hover {
  background: rgba(0, 0, 0, .2);
  cursor: pointer;
}


#vacations {
  display: flex;
  width: 100%;

  @media all and (max-width: 768px) {
    flex-direction: column-reverse;
  }
}

.right-side {
  width: 283px;
}

.isDisabled {
  opacity: .4;
}

#modal-vacation {
  input:not([type=checkbox]):not([type=radio]):not(.k-input):not(.k-textbox){
    padding: 15px 16px;
    color: #656565;
    font-size: 16px;
    border: 1px solid var(--additional-grey-300);
    height: 45px;
  }

  #vacation-form {
    .color-container {
      display: flex !important;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;

      .color-other input[type="radio"] {
        position: relative;
        display: inline-block;
        width: 24px;
        height: 24px;
        margin: auto 8px 0 0;
        border: none !important;
        border-radius: 6px;
        cursor: pointer;
      }

      .color-other input[type="radio"]:checked:after {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        border: 2px solid var(--secondary-400);
        border-radius: 8px;
        opacity: .8;
      }

      .k-radio::before {
        content: none !important;
        transform: none !important;
      }

      .color-other input[type="radio"]:first-child {
        background-color: #D92E2E !important;
        margin-left: 16px;
      }

      .color-other input[type="radio"]:nth-child(2) {
        background-color: #E8B688;
      }

      .color-other input[type="radio"]:nth-child(3) {
        background-color: #792ED9;
      }

      .color-other input[type="radio"]:nth-child(4) {
        background-color: #699BF7;
      }

      .color-other input[type="radio"]:nth-child(5) {
        background-color: #A15BA3;
      }

      .color-other input[type="radio"]:last-child {
        background-color: #3C2ED9;
      }
    }

    .error-info {
      margin-bottom: 14px;
      width: 100%;
      background: var(--statistics-07);
      border: 1px dashed var(--statistics-07);
      border-radius: 10px;
      padding: 12px 16px;
      h2 {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        color: var(--white);
      }
      p {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: var(--white);
      }
    }
  }
}



@-webkit-keyframes sk-bouncedelay {
  0%, 100%, 80% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1)
  }
}

@keyframes sk-bouncedelay {
  0%, 100%, 80% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}



.day[style='background-color: var(--secondary-300);']{
  background: transparent !important;
  .day-content{
    color: var(--white) !important;
    background: var(--secondary-300);
    border-radius: 50% !important;
    width: 25px;
    height: 25px;
    margin: 0 auto;
    padding: 5px 5px ;
  }
}

.day[style='background-color: var(--additional-grey-300);'].round-left, .day[style='background-color: var(--additional-grey-300);'].round-right{
  background: transparent !important;
  border-radius: 10px;
  overflow: hidden;
  .day-content{
    color: var(--white) !important;
    //color: #3D455F !important;
    background: var(--additional-grey-300) !important;
    position: relative;
    border-radius: 10px !important;
    width: calc(32px);
    height: calc(32px);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

//.day[style='background-color: rgb(127, 137, 169);'] {
//  .day-content{
//    color: var(--white) !important;
//  }
//}

.day[style='background-color: rgb(164, 235, 48);'], .day[style='background-color: rgb(252, 222, 116);'],
.day[style='background-color: var(--events-violete);'], .day[style='background-color: rgb(164, 237, 255);'],
.day[style='background-color: rgb(217, 46, 46);'], .day[style='background-color: rgb(60, 46, 217);'],
.day[style='background-color: rgb(232, 182, 136);'], .day[style='background-color: rgb(121, 46, 217);'],
.day[style='background-color: rgb(105, 155, 247);'], .day[style='background-color: rgb(161, 91, 163);'],
.day[style='background-color: rgb(232, 182, 136);'], .day[style='background-color: var(--events-lt-blue);'] {
  background: transparent !important;
  .day-content{
    position: relative;
    &:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
}

.day[style='background-color: rgb(164, 237, 255);'].round-right,.day[style='background-color: var(--events-violete);'].round-right,
.day[style='background-color: rgb(164, 235, 48);'].round-right, .day[style='background-color: rgb(252, 222, 116);'].round-right,
.day[style='background-color: rgb(60, 46, 217);'].round-right, .day[style='background-color: rgb(217, 46, 46);'].round-right,
.day[style='background-color: rgb(232, 182, 136);'].round-right, .day[style='background-color: rgb(121, 46, 217);'].round-right,
.day[style='background-color: rgb(105, 155, 247);'].round-right, .day[style='background-color: rgb(161, 91, 163);'].round-right,
.day[style='background-color: rgb(232, 182, 136);'].round-right, .day[style='background-color: var(--events-lt-blue);'].round-right{
  background: transparent !important;
  .day-content{
    position: relative;
    &:after{
      content: "";
      position: absolute;
      width: calc(100% - 5px);
      height: 100%;
      opacity: 0.4;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
}

.calendar table.month {
  .day[style='background-color: rgb(164, 237, 255);'].round-left, .day[style='background-color: var(--events-violete);'].round-left,
  .day[style='background-color: rgb(164, 235, 48);'].round-left, .day[style='background-color: rgb(252, 222, 116);'].round-left,
  .day[style='background-color: rgb(60, 46, 217);'].round-left, .day[style='background-color: rgb(217, 46, 46);'].round-left,
  .day[style='background-color: rgb(232, 182, 136);'].round-left, .day[style='background-color: rgb(121, 46, 217);'].round-left,
  .day[style='background-color: rgb(105, 155, 247);'].round-left, .day[style='background-color: rgb(161, 91, 163);'].round-left,
  .day[style='background-color: rgb(232, 182, 136);'].round-left, .day[style='background-color: var(--events-lt-blue);'].round-left {
    background: transparent !important;
    .day-content{
      position: relative;
      &:after{
        content: "";
        position: absolute;
        width: calc(100% - 5px);
        height: 100%;
        opacity: 0.4;
        left: 5px;
        top: 0;
        z-index: -1;
      }
    }
  }
}

.day[style='background-color: var(--events-violete)'].round-left .day-content { &:after{background-color: var(--events-violete);} }
.day[style='background-color: rgb(164, 237, 255);'].round-left  .day-content { &:after{background-color: rgb(164, 237, 255);} }
.day[style='background-color: rgb(164, 235, 48);'].round-left .day-content { &:after{background-color: rgb(164, 235, 48);} }
.day[style='background-color: rgb(252, 222, 116);'].round-left  .day-content { &:after{background-color: rgb(252, 222, 116);} }
.day[style='background-color: rgb(217, 46, 46);'].round-left .day-content { &:after{background-color: rgb(217, 46, 46);} }
.day[style='background-color: rgb(60, 46, 217);'].round-left .day-content { &:after{background-color: rgb(60, 46, 217);} }
.day[style='background-color: rgb(121, 46, 217);'].round-left  .day-content { &:after{background-color: rgb(121, 46, 217);} }
.day[style='background-color: rgb(105, 155, 247);'].round-left  .day-content { &:after{background-color: rgb(105, 155, 247);} }
.day[style='background-color: rgb(161, 91, 163);'].round-left  .day-content { &:after{background-color: rgb(161, 91, 163);} }
.day[style='background-color: rgb(232, 182, 136);'].round-left  .day-content { &:after{background-color: rgb(232, 182, 136);} }
.day[style='background-color: var(--events-lt-blue);'].round-left  .day-content { &:after{background-color: var(--events-lt-blue);} }

//

.day[style='background-color: rgb(164, 235, 48);'] .day-content { &:before{background-color: rgb(164, 235, 48);} }
.day[style='background-color: rgb(252, 222, 116);'] .day-content { &:before{background-color: rgb(252, 222, 116);} }
.day[style='background-color: var(--events-violete);'] .day-content { &:before{background-color: var(--events-violete);} }
.day[style='background-color: rgb(164, 237, 255);'] .day-content { &:before{background-color: rgb(164, 237, 255);} }
.day[style='background-color: rgb(217, 46, 46);'] .day-content { &:before{background-color: rgb(217, 46, 46);} }
.day[style='background-color: rgb(60, 46, 217);'] .day-content { &:before{background-color: rgb(60, 46, 217);} }
.day[style='background-color: rgb(121, 46, 217);'] .day-content { &:before{background-color: rgb(121, 46, 217);} }
.day[style='background-color: rgb(105, 155, 247);'] .day-content { &:before{background-color: rgb(105, 155, 247);} }
.day[style='background-color: rgb(161, 91, 163);'] .day-content { &:before{background-color: rgb(161, 91, 163);} }
.day[style='background-color: rgb(232, 182, 136);'] .day-content { &:before{background-color: rgb(232, 182, 136);} }
.day[style='background-color: var(--events-lt-blue);'] .day-content { &:before{background-color: var(--events-lt-blue);} }


.day[style='background-color: rgb(164, 237, 255);'].round-right .day-content { &:after{background-color: rgb(164, 237, 255);} }
.day[style='background-color: var(--events-violete);'].round-right .day-content { &:after{background-color: var(--events-violete);} }
.day[style='background-color: rgb(164, 235, 48);'].round-right  .day-content { &:after{background-color: rgb(164, 235, 48);} }
.day[style='background-color: rgb(252, 222, 116);'].round-right  .day-content { &:after{background-color: rgb(252, 222, 116);} }
.day[style='background-color: rgb(217, 46, 46);'].round-right  .day-content { &:after{background-color: rgb(217, 46, 46);} }
.day[style='background-color: rgb(60, 46, 217);'].round-right  .day-content { &:after{background-color: rgb(60, 46, 217);} }
.day[style='background-color: rgb(121, 46, 217);'].round-right  .day-content { &:after{background-color: rgb(121, 46, 217);} }
.day[style='background-color: rgb(105, 155, 247);'].round-right  .day-content { &:after{background-color: rgb(105, 155, 247);} }
.day[style='background-color: rgb(161, 91, 163);'].round-right  .day-content { &:after{background-color: rgb(161, 91, 163);} }
.day[style='background-color: rgb(232, 182, 136);'].round-right  .day-content { &:after{background-color: rgb(232, 182, 136);} }
.day[style='background-color: var(--events-lt-blue);'].round-right  .day-content { &:after{background-color: var(--events-lt-blue);} }


.calendar table.month{
  td.day.round-left, td.day.round-right {
    .day-content{
      color: var(--white) !important;
      &:before{
        top: -4px;
        width: 32px !important;
        height: 32px !important;
        border-radius: 10px;
        opacity: 1;
      }
    }
  }

  td.day.round-left:hover, td.day.round-right:hover {
    background: rgba(0, 0, 0, 0.2) !important;
    width: 100%;
    height: 32px;
    border-radius: 10px !important;
    .day-content:hover{
      background: none;
    }
  }

  td.day[style='background-color: rgb(164, 237, 255);'].round-left,td.day[style='background-color: rgb(164, 237, 255);'].round-right {
    .day-content {
      color: #3D455F !important;
    }
  }
}

#events-calendar {
  &.calendar {
    padding-top: 30px;
    .month-container {
      .month {
        .day-content {
          color: var(--additional-grey-300);
          width: 28px;
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        tr > td {
          border: 2px solid var(--additional-page-bg);
          width: 30px;
          height: 30px;
          border-radius: 10px;
          color: var(--white);
        }
        tr > td:hover {
          background: inherit;
        }
        tr {
          td.day[style='background-color: var(--secondary-300);'] {
            border-radius: 22px;
            .day-content {
              color: var(--white);
            }
          }

          td.day[style='background-color: rgb(177, 189, 255);'] {
            .day-content {
              color: var(--white);
            }
          }

          td.day[style='background-color: rgb(252, 163, 163);'] {
            .day-content {
              color: var(--white);
            }
          }

          td.day[style='background-color: rgb(238, 95, 241);'] {
            .day-content {
              color: var(--white);
            }
          }

          td.day[style='background-color: rgb(192, 156, 238);'] {
            .day-content {
              color: var(--white);
            }
          }
        }
      }
    }
  }
}

#vacations {
  .month-container {
    td.day[style='background-color: var(--secondary-300);'] {
      border-radius: 22px;
      .day-content {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--white);
      }
    }
  }
}






