@import "fonts";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "@progress/kendo-theme-default/scss/_variables.scss";

@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/mixins/_grid";
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@import "bootstrap/scss/grid";
@import "bootstrap/scss/utilities";
@import "calendar";
@import "table-mobile";
@import "./../scss/ngx-bootstrap/ngx-bootstrap-reset";
@import "maplibre-gl/dist/maplibre-gl.css";

* {
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background: var(--additional-page-bg);
  }


  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    background-color: var(--scroll-bg);
    border-radius: 4px;
    outline: 1px solid transparent;
    background-clip: content-box;
    border: 2px solid var(--additional-page-bg);
    &:hover{
      background-color: var(--scroll-bg-hover);
      width: 10px;
      border: none;
    }
  }
}

.t-color {
  &-blue {
    color: var(--primary-300)
  }
}

img {
  display: block;
  max-width: 100%;
}

body, html {
  font-family: 'GT Walsheim Pro', sans-serif;
  padding: 0;
  margin: 0;
  @media all and (max-width: 480px) {
    position: relative;
  }
}

textarea {
  resize: vertical;
}

input, button {
  &:focus {
    outline: none;
  }
}
div {
  user-select: text;
}
@import "kendo/kendo";
@import "kendo/_calendar";

.k-filtercell-operator {
  display: none;
  > .k-button.k-clear-button-visible {
      visibility: hidden!important;
  }
}
.hovered{
  z-index: 0;
  &:hover{
    z-index: 1;
  }
}

.highlighted-text {
  background-color: #93CDFC;
}
#version{
  position: fixed;
  right: 16px;
  bottom: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: var(--additional-key-550, #747FA0);
  z-index: 999999999;
  user-select: none;
  pointer-events: none;
  @media all and (max-width: 768px) {
    right: 50%;
    left: 45%;
    bottom: 16px;
  }
}

#history-of-changes {
  .no-data img {
    max-width: 200px;
  }
  .no-data-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
  }
}

.fds-label {
  padding: 2px 4px;
  display: inline-block;
  border-radius: 5px;
  background-color: var(--accent-primary-425);
  color: var(--white);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
}
